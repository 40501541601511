.signup-form {
    width: 483px;
    height: 650px;
    display:flex;
    padding: 48px 0px 0px;
    flex-direction: column;
    align-items: center;
    background-color: rgb(37, 37, 37);
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 7px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    overflow-y: scroll;
}

.signup-scroll-content {
    height: 650px;
    overflow-y: scroll;
    padding-left: 15px;
    padding-right: 15px;
}

.signup-text-link {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: rgb(255, 59, 92);
    cursor: pointer;
}


.signup-form::-webkit-scrollbar {
    width: 8px;
}

.signup-form::-webkit-scrollbar-thumb {
    width: 6px;
    height: 100%;
    border-radius: 3px;
    background: rgba(255, 255, 255, .08);
}

.signup-scroll-content::-webkit-scrollbar-thumb {
    width: 6px;
    height: 100%;
    border-radius: 3px;
    background: rgba(255, 255, 255, .08);
}

.signup-scroll-content::-webkit-scrollbar {
    width: 8px;
}

.signup-input {
    width: 285px;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 600;
    border: 1px solid rgba(255, 255, 255, 0);
    font-size: 15px;
    padding: 0px 12px;
    height: 44px;
    background: rgb(46, 46, 46);
    margin-bottom: 15px;
    display: flex;
    outline: none;
}

    #signup-textarea {
        height: 100px;
        color: rgb(46, 46, 46);
    }

    .signup-button {
        border-radius: 4px;
        border: none;
        color: rgb(255, 255, 255);
        background-color: rgb(255, 59, 92);
        min-width: 320px;
        min-height: 46px;
        font-size: 16px;
        line-height: 22px;
        margin-top: 20px;
    }

    .signup-button:hover {
        background-color: rgb(250, 41, 76);
        cursor: pointer;
    }

    #lastname-input {
        margin-top: 0px;
    }

    #modal-content > form > div:nth-child(1) {
        color: rgb(201, 0, 0);
        font-size: 14px;
    }

    #modal-content > form > div.signup-scroll-content > div:nth-child(8) {
        padding-bottom: 105px;
    }

    #modal-content > form > div.signup-scroll-content > div:nth-child(9) {
        height: 75px;
    }

    .submit-button-signup {
        border-radius: 4px;
        border: none;
        color: rgb(255, 255, 255);
        background-color: rgb(255, 59, 92);
        min-width: 340px;
        min-height: 46px;
        font-size: 16px;
        line-height: 22px;
        cursor: pointer;
    }

    .submit-buttons-signup {
        margin-bottom: 25px;
        margin-top: 10px;
    }

    .upload-signup-header {
        color: white;
    }
