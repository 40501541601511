#root > div > form > input[type=file] {
    margin-top: 25%;
    margin-left: 25%;
    color: white;
}

#root > div > div:nth-child(3) {
    padding-top: 5%;
}

.fast-forward-feed {
    margin-left: 45%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.item-header3 {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: 5%;
    padding-bottom: 1%;
}

.item-header {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: 5%;
}

.item-wrapper {
    box-shadow: rgb(255 255 255 / 12%) 0px 1px 1px;
    padding-bottom: 2%;
}

.item-header2 {
    display: flex;
    gap: 10px;
    color: white;
    padding-bottom: 5px;
}

.profileImage {
    width: 50px;
    height: 50px;
    border-radius: 33px;
}

.caption {
    padding-bottom: 5px;
    color: white;
    text-decoration: none;
    width: 330px;
    display: block;
}

.video {
    margin-left: 9%;
    padding-top: 0;
    top: 0;
    background-color: black;
    background-size: cover;
    border-radius: 8px;
    cursor: pointer;
}

.video-username {
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    color: white;
}

.video-username:hover {
    cursor: pointer;
    text-decoration: underline;
}

.video-name {
    font-size: 14px;
    margin-top: 4px;
}

#root > div > div.fastForward-wrapper {
    display: flex;
    align-items: center;
    height: 890px;
    width: 100%;
}

#root > div > div.fastForward-wrapper > video {
    background-color: black;
}

#root > div > div > div > div > div.item-header3 > div.right > div.caption-wrapper > a:hover {
    text-decoration: underline;
}

.video-comment {
    display: flex;
    gap: 10px;
}

#share-icon {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    margin-left: 1px;
}

#liked-feed {
    font-size: 18px;
    color: rgb(255, 59, 92);
    cursor: pointer;
    margin-left: 1px;
}

#un-liked-feed {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    margin-left: 1px;
}

#comment-icon {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    margin-left: 1px;
}

.video-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-bottom: 2%;
}

.comments-sidebar {
    height: 700px;
    width: 100%;
}

.comment-counter {
    color: white;
    font-size: 12px;
}

.textarea-comments {
    position: absolute;
    border-top: .3px solid rgb(89, 88, 88);
    padding-top: 15px;
    width: 700px;
}

#form1 > label > input[type=text] {
    border: none;
    background-color: rgb(46, 46, 46);
    caret-color: rgb(255, 59, 92);
    color: rgba(255, 255, 255, 0.9);
    outline: none;
    width: 70%;
    height: 35px;
    padding-left: 15px;
    margin-left: 5%;
    border-radius: 7px;
}


.comments-wrapper {
    height: 100%;
    width: 700px;
}

.comment-button {
    margin-left: 5px;
    border: none;
    background-color: rgb(18, 18, 18);
    color: rgb(156, 155, 155);
}

.comment-button-active {
    margin-left: 5px;
    border: none;
    background-color: rgb(18, 18, 18);
    color: rgb(255, 59, 92);
    cursor: pointer;
}

#form1 > ul {
    width: 500px;
}

.wrapper-plus-summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.copy-wrapper {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 22px;
    height: 22px;
    font-size: 0px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.12);
    padding: 8px;
    margin-left: 5%;
    margin-top: 10%;
    cursor: pointer;
}

.like-wrapper-feed {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 22px;
    height: 22px;
    font-size: 0px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.12);
    padding: 8px;
    margin-left: 5%;
    margin-top: 10%;
    cursor: pointer;
}

.comment-wrapper {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 22px;
    height: 22px;
    font-size: 0px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.12);
    padding: 8.5px;
    margin-left: 5%;
    margin-top: 10%;
    cursor: pointer;
}

.comment-wrapper:hover {
    background-color: rgba(224, 214, 214, 0.12);
}

.like-wrapper-feed:hover {
    background-color: rgba(224, 214, 214, 0.12);
}

.copy-wrapper:hover {
    background-color: rgba(224, 214, 214, 0.12);
}

.comment-wrapper2 {
    display: flex;
    flex-direction: column;
    padding-top: 2%;
}

.profileImage2 {
    width: 28px;
    height: 28px;
    border-radius: 33px;
}

#root > div > div.fastForward-wrapper > div > div.comments-wrapper > div > div.scroll-body > div > div.item-header > div {
    color: white;
    font-size: 18px;
}

.comment-body {
    margin-left: 11.5%;
    margin-top: 5px;
    top: 0;
    color: rgba(255, 255, 255, 0.9);
    font-size: 16px;
}

.comment-buttons {
    margin-left: 11.5%;
    margin-top: 10px;
    display: flex;
    gap: 10px;
    color: rgba(255, 255, 255, 0.5);;
    font-size: 14px;
    cursor: pointer;
}

.scroll-body {
    overflow-y: scroll;
    max-height: 600px;
}

.edit-text {
    margin-left: 11.5%;
    resize: none;
}

.exit-redirect {
    position: absolute;
    left: 0;
    right: 0;
    padding-left: 15px;
    font-size: 34px;
    color: grey;
    margin-bottom: 840px;
    z-index: 50;
}

.exit-redirect:hover {
    opacity: .7;
}

.follow-user-button {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    color: rgb(255, 59, 92);
    border-color: rgb(255, 59, 92);
    background-color: rgba(255, 255, 255, 0.08);
    min-width: 106px;
    min-height: 28px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 6px 8px;
    user-select: none;
    cursor: pointer;
    box-sizing: border-box;
    margin-left: 150px;
}

.follow-user-button:hover {
    cursor: pointer;
    background-color: rgb(36, 16, 16);}

.following-user-button {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(22, 24, 35, 0.12);
    background-color: rgb(35, 35, 35);
    min-width: 106px;
    min-height: 28px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 6px 8px;
    user-select: none;
    cursor: pointer;
    box-sizing: border-box;
    margin-left: 150px;
}

.following-user-button:hover {
    cursor: pointer;
    background-color: #141414;
}

.follow-feed {
    margin-left: 45%;
    margin-top: 4%;
}

.upload-headline-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 5%;
}

.upload-title {
    color: white;
}

.upload-header-text {
    color: white;
}

.caption-label {
    color: white;
}

.upload-subtitle {
    color: rgb(169, 166, 166);
    margin-bottom: 45px;
}

.upload-page {
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 10%;
    width: 1400px;
    height: 700px;
    background-color: rgb(37, 37, 37);
    box-shadow: rgb(0 0 0 / 6%) 0px 2px 8px;
}

.upload-block {
    border: 2px dashed rgb(169, 166, 166);
    width: 250px;
    height: 350px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-list {
    list-style: none;
    padding-left: 0px;
}

.upload-sub-text {
    font-size: 12px;
    top: 0;
    bottom: 0;
    color: rgb(169, 166, 166);
}

.upload-image {
    display: flex;
    color: rgb(255, 59, 92);
    font-size: 20px;
    margin-left: 27%;
    gap: 55px;
    align-items: center;
}

.upload-item {
    display: flex;
    gap: 15px;
    padding: 15px;
    padding-top: 5px;
    padding-left: 5px;
    border-radius: 7px;
    background-color: rgb(62, 62, 62);
    width: 250px;
}

#upload-item-1 {
    animation-name: myAnimation;
    animation-iteration-count: infinite;
    animation-duration: 15s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

#upload-item-2 {
    animation-name: myAnimation;
    animation-iteration-count: infinite;
    animation-duration: 15s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}

#upload-item-3 {
    animation-name: myAnimation;
    animation-iteration-count: infinite;
    animation-duration: 15s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
}

.upload-item-text {
    color: white;
}

#upload-number-1 {
    font-size: 60px;
    line-height: 10px;
}

#upload-number-2 {
    font-size: 60px;
    line-height: 10px;
}

#upload-number-3 {
    font-size: 60px;
    line-height: 10px;
}

#cloud-icon {
    font-size: 28px;
    color: grey;
}

.file-drop {
    margin-top: 35px;
    margin-left: 20px;
    color: white;
    width: 200px;
    overflow: hide;
}

.form-fields {
    display: flex;
    gap: 55px;
}

.caption-block {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 35px;
    width: 650px;
    margin-top: 200px;
    margin-left: 8%;
}

.caption-input {
    border: none;
    background-color: rgb(62, 62, 62);
    caret-color: rgb(255, 59, 92);
    color: rgba(255, 255, 255, 0.9);
    outline: none;
    width: 100%;
    height: 35px;
    padding-left: 15px;
}

.submit-buttons {
    margin-left: 45%;
    position: relative;
    width: 150px;
    margin-bottom: 550px;
    display: flex;
    gap: 35px;
}

.discard-button {
    border-radius: 4px;
    border: .4px solid rgb(189, 188, 188);
    color: black;
    background-color: white;
    min-width: 180px;
    min-height: 46px;
    font-size: 16px;
    line-height: 22px;
}

.submit-button-upload {
    border-radius: 4px;
    border: none;
    color: rgb(255, 255, 255);
    background-color: rgb(255, 59, 92);
    min-width: 380px;
    min-height: 46px;
    font-size: 16px;
    line-height: 22px;
}

.submit-button-upload:hover {
    background-color: rgb(250, 41, 76);
    cursor: pointer;
}

.discard-button:hover {
    background-color: rgb(243, 243, 243);
    cursor: pointer;
}

#root > div > div.upload-page > form > div.submit-buttons > p {
    color: white;
}

.share-link {
    border-radius: 5px;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-left: 5%;
    margin-top: 2%;
    width: 81%;
    height: 30px;
    display: flex;
}

.share-item-2 {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1 1 auto;
    padding: 7px 0px 5px 12px;
    background-color: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.75);
    font-size: 14px;
    line-height: 20px;
}

.share-line {
    display: flex;
    align-items: center;
    width: 100%;
}

.share-item {
    color: white;
    height: 24px;
    padding: 5px;
    padding-left: 15px;
    width: 16%;
    font-size: 14px;
    border: none;
    outline: none;
    background-color: rgba(157, 157, 157, 0.12);
}

.share-item:hover {
    background-color: rgb(27, 27, 27);
    cursor: pointer;
}

.signin-text-comments {
    color: rgb(255, 59, 92);
    font-weight: 600;
    border-radius: 2px;
    padding: 14px 16px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
}

.comment-signin-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 90%;
    margin-left: 25px;
    background: rgb(37, 37, 37);
    border-radius: 2px;
}

.follow-button-followed {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0);
    background-color: rgb(35, 35, 35);
    min-width: 86px;
    min-height: 23px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 100;
    cursor: pointer;
    margin-left: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.follow-button-unfollowed {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    color: rgba(255, 59, 92, 1);
    border-color: rgba(255, 59, 92, 1);
    background-color: rgba(255, 255, 255, .08);
    min-width: 86px;
    min-height: 23px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 75%;
    cursor: pointer;
}

.follow-button-unfollowed:hover {
    background-color: rgb(36, 16, 16);
}

.follow-button-followed:hover {
    border: 1px solid white
}

.follow-button-followed-user {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0);
    background-color: rgb(35, 35, 35);
    min-width: 198px;
    min-height: 36px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 100;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.follow-button-unfollowed-user {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0);
    background-color: rgb(255, 59, 92);
    min-width: 198px;
    min-height: 36px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 100;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.summary-line {
    display: flex;
    margin-left: 5%;
    margin-top: 5%;
    gap: 4%;
}

.like-summary {
    display: flex;
    align-items: center;
    gap: 5px;
}

.like-wrapper {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 22px;
    height: 22px;
    font-size: 0px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.12);
    padding: 6px;
    margin-left: 5%;
    margin-top: 10%;
}

.comment-wrapper-2 {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 22px;
    height: 22px;
    font-size: 0px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.12);
    padding: 6px;
    margin-left: 5%;
    margin-top: 10%;
}

.like-wrapper:hover {
    background-color: rgba(224, 214, 214, 0.12);
    cursor: pointer;
}

#liked {
    font-size: 18px;
    color: rgb(255, 59, 92);
    cursor: pointer;
}

#un-liked {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
}

#share-icon {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    margin-left: 2px;
}

#comment {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.9);
}

.like-number {
    color: rgba(255, 255, 255, .75);
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    margin-top: 10%;
}

@keyframes myAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .fast-forward-feed {
        margin-left: 35%;
    }
}
